import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Tab from './Tab';
import TabList from './TabList';
import TabPanel from './TabPanel';

// Tabs component to manage tab navigation and content display
const Tabs = ({
  tabs,
  defaultActiveIndex = 0,
  tabProps = {},
  tabListProps = {},
  tabPanelProps = {},
  tabClassName,
  tabListClassName,
  tabPanelClassName,
}) => {
  // Validate defaultActiveIndex
  if (defaultActiveIndex < 0 || defaultActiveIndex >= tabs.length) {
    throw new Error(
      `defaultActiveIndex ${defaultActiveIndex} is out of range. It should be between 0 and ${
        tabs.length - 1
      }.`
    );
  }

  const [activeTabIndex, setActiveTabIndex] = useState(defaultActiveIndex);

  // State to track which tabs have been loaded
  // This is used to prevent loading content for tabs that have not been visited
  // and keep the content loaded in DOM when switching between tabs
  const [loadedTabs, setLoadedTabs] = useState(new Set([defaultActiveIndex]));

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
    setLoadedTabs((prev) => new Set(prev).add(index));
  };

  return (
    <div>
      <TabList className={tabListClassName} {...tabListProps}>
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={index}
              onClick={() => handleTabClick(index)}
              tabIndex={index}
              isSelected={activeTabIndex === index}
              className={tabClassName}
              {...tabProps}
            >
              {tab.header}
            </Tab>
          );
        })}
      </TabList>
      {tabs.map((tab, index) => {
        return (
          <TabPanel
            key={index}
            value={activeTabIndex}
            index={index}
            hasLoaded={loadedTabs.has(index)}
            className={tabPanelClassName}
            {...tabPanelProps}
          >
            {tab.content}
          </TabPanel>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.node.isRequired,
      content: PropTypes.element.isRequired,
    })
  ).isRequired,
  defaultActiveIndex: PropTypes.number,
  tabProps: PropTypes.object,
  tabListProps: PropTypes.object,
  tabPanelProps: PropTypes.object,
  tabClassName: PropTypes.string,
  tabListClassName: PropTypes.string,
  tabPanelClassName: PropTypes.string,
};

export default Tabs;
