import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import Card from 'components/common/card/Card';
import Item from 'components/common/item/Item';
import { Notify } from 'components/common/notify/Notify';
import EmptyPage from 'components/common/emptypage/EmptyPage';
import ClientDropDown from 'components/common/dropdown/clientDropDown';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';

import {
  formatCurrency,
  formatCurrencyWithoutSign,
} from 'utils/common/formatter';

import {
  CLIENT_DATA_OVERVIEW_FAILURE,
  MAPPING_FAILURE,
} from 'constants/errorMessages';
import { UNMAPPED_CATEGORY } from 'constants/insurance';
import { WORK_TRACKER_AGGREGATION_FAILURE } from 'constants/errorMessages';
import { getClientDataOverview } from 'services/clientDashboard';
import { getInsuranceMapping, getTransactionMapping } from 'services/mapping';
import {
  mappingDataConfig,
  clientDataOverviewDetailConfig,
} from './clientDashboardConfig';
import { getWorkTrackerAggByWeek } from 'services/workTracker';
import { formatDate } from 'utils/common/formatter';
import Icon from 'components/common/icons/Icons';
import { colors } from 'constants/colors';

import LineGraph from './LineGraph';
import { CustomTooltip } from './customFormatter';

const ClientDashboard = () => {
  const clientList = useSelector((state) => state.clientList);
  const selectedClientId = useSelector((state) => state.selectedClientId);

  const selectedClient = clientList.find(
    (client) => parseInt(client.client_id) === parseInt(selectedClientId)
  );
  const [barHovered, setBarHovered] = useState(false);
  const [clientDataLoading, setClientDataLoading] = useState(false);
  const [clientDataOverview, setClientDataOverview] = useState();

  const [mappingData, setMappingData] = useState({});
  const [mappingDataLoading, setMappingDataLoading] = useState({});

  const [workTrackerAgg, setWorkTrackerAgg] = useState([]);
  const [workTrackerAggLoading, setWorkTrackerAggLoading] = useState(false);

  // Fetches and formats the client overview data.
  const getClientOverview = async () => {
    try {
      setClientDataLoading(true);

      const clientOverview = await getClientDataOverview(selectedClientId);

      const formattedData = {
        ...clientOverview,
        balance_count_overview: clientOverview.balance_count_overview.map(
          (item) => ({
            ...item,
            snapshot_date: formatDate(item.snapshot_date),
          })
        ),
        data_overview: {
          ...clientOverview.data_overview,
          total_count: formatCurrencyWithoutSign(
            clientOverview.data_overview.total_count
          ),
          max_post_date: formatDate(clientOverview.data_overview.max_post_date),
          last_data_run_date: formatDate(
            clientOverview.data_overview.last_data_run_date
          ),
          total_balance: `(${formatCurrency(
            -clientOverview.data_overview.total_balance
          )})`,
        },
      };

      setClientDataOverview(formattedData);
    } catch (error) {
      const errorMessage =
        error.response.data.detail || CLIENT_DATA_OVERVIEW_FAILURE;

      Notify.error({
        title: errorMessage,
      });
    } finally {
      setClientDataLoading(false);
    }
  };

  // Fetches and processes unmapped insurance and transaction mapping data
  const getMappingData = async () => {
    try {
      setMappingDataLoading(true);

      const mappingPromises = [
        getInsuranceMapping(selectedClientId),
        getTransactionMapping(selectedClientId),
      ];

      const [insuranceMapping, transactionMapping] = await Promise.all(
        mappingPromises
      );

      const unmappedInsurance =
        insuranceMapping.find(
          (item) => item.category_name === UNMAPPED_CATEGORY
        )?.count || 0;

      const unmappedTransaction =
        transactionMapping.find(
          (item) => item.category_name === UNMAPPED_CATEGORY
        )?.count || 0;

      setMappingData({ unmappedInsurance, unmappedTransaction });
    } catch (error) {
      const errorMessage = error.response.data.detail || MAPPING_FAILURE;
      Notify.error({
        title: errorMessage,
      });
    } finally {
      setMappingDataLoading(false);
    }
  };

  const getWorkTrackerAgg = async () => {
    try {
      setWorkTrackerAggLoading(true);
      const data = await getWorkTrackerAggByWeek({
        client_id: selectedClientId,
      });
      setWorkTrackerAgg(data);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.detail
        : WORK_TRACKER_AGGREGATION_FAILURE;
      Notify.error({
        title: errorMessage,
      });
      setWorkTrackerAgg([]);
    } finally {
      setWorkTrackerAggLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedClientId) return;

    getClientOverview();
    getMappingData();
    getWorkTrackerAgg();
  }, [selectedClient]);

  return (
    <>
      <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
        <div className="profile mb-3x ml-auto">
          <ClientDropDown />
        </div>
        <div className="d-flex justify-content-between align-items-center mb-1x">
          <h1>{selectedClient?.display_name || 'Client Dashboard'}</h1>
          <button
            className="btn-has-icon ml-5x mr-auto mt-3x"
            onClick={() => {
              window.location.reload(false);
            }}
          >
            <Icon icon="refresh" size={12} color="#546071" className="mr-2x" />
            Refresh
          </button>
        </div>
      </div>
      {!selectedClientId ? (
        <EmptyPage pageName="client dashboard" selectionParameters="client" />
      ) : (
        <div className="client-dashboard-main">
          <Card
            loading={clientDataLoading}
            header="Client Data Overview"
            className="col-span-9"
            contentClassName="content d-flex justify-content-between"
          >
            {clientDataOverviewDetailConfig.map((item, index) => (
              <Item
                key={index}
                label={item.label}
                value={clientDataOverview?.data_overview?.[item.key] || '-'}
                valueClassName={classNames({
                  'color-danger--base': item.key === 'total_balance',
                })}
              />
            ))}
          </Card>

          <Card
            loading={mappingDataLoading}
            className="col-span-3"
            header="Mapping"
            contentClassName="content d-flex justify-content-between"
          >
            {mappingDataConfig.map((item, index) => {
              return (
                <Item
                  key={index}
                  label={item.label}
                  value={mappingData?.[item.key] || '-'}
                />
              );
            })}
          </Card>

          <Card className="col-span-6" header="Balance Count">
            <LineGraph
              balanceCountOverview={clientDataOverview?.balance_count_overview}
            />
          </Card>

          <Card
            className="col-span-6"
            header="Work Tracker"
            loading={workTrackerAggLoading}
          >
            <div style={{ width: '100%', height: '450px' }}>
              {workTrackerAgg.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={workTrackerAgg}
                    isAnimationActive={false}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 30,
                      bottom: 30,
                    }}
                    barSize={50}
                  >
                    <CartesianGrid horizontal={true} vertical={false} />
                    <XAxis dataKey="week">
                      <Label
                        value="Weeks (Past 7)"
                        offset={-20}
                        position="insideBottom"
                      />
                    </XAxis>
                    <YAxis
                      tickFormatter={(tick) =>
                        Number.isInteger(tick) ? tick : ''
                      }
                    >
                      <Label
                        value="Total work units logged"
                        offset={-15}
                        angle={-90}
                        position="insideLeft"
                        style={{ textAnchor: 'middle' }}
                      />
                    </YAxis>
                    <Tooltip
                      content={<CustomTooltip show={barHovered} />}
                      cursor={false}
                      animationDuration={0}
                    />
                    <Bar
                      dataKey="count"
                      fill={colors.primary[15]}
                      onMouseEnter={() => setBarHovered(true)}
                      onMouseLeave={() => setBarHovered(false)}
                    ></Bar>
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <>Nothing to show</>
              )}
            </div>
          </Card>

          <Card className="col-span-9" header="ETL Status">
            ETL Status
          </Card>

          <Card className="col-span-3" header="Actions">
            Actions
          </Card>
        </div>
      )}
    </>
  );
};
export default ClientDashboard;
