import React from 'react';

import PropTypes from 'prop-types';

import {
  Line,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import { formatNumber } from 'utils/common/formatter';
import { customTickFormatter, CustomTooltip } from './customFormatter';
import { colors } from 'constants/colors';

const LineGraph = (props) => {
  const { balanceCountOverview } = props;

  if (!balanceCountOverview) {
    return;
  }

  const updatedBalanceCountOverview = balanceCountOverview.map((item) => ({
    ...item,
    total_balance: Number(item.total_balance),
    total_count: Number(item.total_count),
  }));

  return (
    <ResponsiveContainer width={'100%'} height={400} className="mt-4x">
      <LineChart
        width={500}
        height={300}
        data={updatedBalanceCountOverview}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="0 0" />
        <XAxis
          dataKey="snapshot_date"
          axisLine={false}
          tickFormatter={(tick, index) =>
            customTickFormatter(tick, index, updatedBalanceCountOverview)
          }
          dy={0}
        >
          <Label value="Month" angle={360} position="bottom" dy={10} />
        </XAxis>
        <YAxis yAxisId="left" axisLine={false} tickFormatter={formatNumber}>
          <Label value="Count" angle={-90} position="insideLeft" />
        </YAxis>
        <YAxis
          yAxisId="right"
          orientation="right"
          axisLine={false}
          tickFormatter={formatNumber}
        >
          <Label value="Balance" angle={-90} position="inside" dx={40} />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="total_count"
          stroke={colors.red.tooltipRed}
          strokeWidth={2}
          dot={{
            r: 5,
            fill: colors.red.tooltipRed,
          }}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="total_balance"
          stroke={colors.green.tooltipGreen}
          strokeWidth={2}
          dot={{
            r: 5,
            fill: colors.green.tooltipGreen,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

LineGraph.propTypes = {
  balanceCountOverview: PropTypes.array.isRequired,
};

export default LineGraph;
