import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ValidationError } from 'yup';
import SelectBox from 'components/common/select/Select';
import FormLabel from 'components/common/formLabel/FormLabel';
import { getWorkTrackerAction } from 'services/workTrackerAction';
import { getTransactionComboByClient } from 'services/transactionCombo';
import { Notify } from 'components/common/notify/Notify';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';
import { addWorkTrackers } from 'services/workTracker';
import Input from 'components/common/Input/Input';
import { workTrackerSchema } from './schema';
import { WORK_TRACKER_ADD_SUCCESS_MESSAGE } from 'constants/successMessage';

const WorkTrackerForm = ({ clientId, accountId, formSubmitted }) => {
  const [transactionCombo, setTransactionCombo] = useState([]);
  const [majorAction, setMajorAction] = useState([]);
  const [minorAction, setMinorAction] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({});

  const initialFormData = {
    major_action_id: null,
    minor_action_id: null,
    user_note: '',
    transaction_combo_id: null,
    writeoff_amount: '',
    posting_id: '',
    post_date: new Date().toISOString().split('T')[0],
  };
  const [formData, setFormData] = useState(initialFormData);

  const fetchWorkTrackerAction = async (workTrackerId = -1) => {
    try {
      const data = await getWorkTrackerAction({
        major_action_id: workTrackerId,
      });
      const formattedData = data.map((element) => ({
        label: element.action_name,
        value: element.worktracker_action_id,
      }));
      if (workTrackerId === -1) {
        setMajorAction(formattedData);
      } else {
        setMinorAction(formattedData);
      }
    } catch (error) {
      Notify.error({
        title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  const fetchTransactionCombo = async (transactionCodeName = '') => {
    try {
      const data = await getTransactionComboByClient(clientId, {
        transaction_code_name: transactionCodeName,
      });

      const formattedData = data.map((element) => ({
        label: element.transaction_description,
        value: element.transaction_combo_id,
      }));
      setTransactionCombo(formattedData);
    } catch (error) {
      Notify.error({
        title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  useEffect(() => {
    fetchTransactionCombo();
    fetchWorkTrackerAction();
  }, [clientId]);

  useEffect(() => {
    if (!formData.major_action_id) {
      return;
    }

    fetchWorkTrackerAction(formData.major_action_id);
  }, [formData.major_action_id]);

  const handleMajorActionChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      major_action_id: selectedOption.value,
      minor_action_id: '',
    }));
    setError({ ...error, major_action_id: '' });
  };

  const handleMinorActionChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      minor_action_id: selectedOption.value,
    }));
    setError({ ...error, minor_action_id: '' });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFormBlur = async (e) => {
    const { name, value } = e.target;
    try {
      await workTrackerSchema.validateAt(name, {
        ...formData,
        [name]: value,
      });
      setError({ ...error, [name]: '' });
    } catch (err) {
      setError({ ...error, [name]: err.message });
    }
  };

  const handleSubmitButtonClick = async () => {
    const payload = {
      client_id: clientId,
      major_action_id: formData.major_action_id,
      minor_action_id: formData.minor_action_id,
      user_note: formData.user_note,
      writeoff_amount: formData.writeoff_amount,
      account_id: accountId,
      transaction_combo_id: formData.transaction_combo_id,
      posting_id: formData.posting_id,
      post_date: formData.post_date,
    };

    try {
      setError({});
      await workTrackerSchema.validate(payload, { abortEarly: false });
      setIsSubmitting(true);
      await addWorkTrackers(payload);
      Notify.success({
        title: WORK_TRACKER_ADD_SUCCESS_MESSAGE,
      });
      formSubmitted();
      setFormData(initialFormData);
    } catch (err) {
      if (err instanceof ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setError(validationErrors);

        return;
      }

      Notify.error({
        title: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="title mb-4x">
        <h4>Tracker</h4>
      </div>
      <div className="work-tracker-form pr-4x pb-4x">
        <div className="form-group work-tracker-form__dropdown">
          <FormLabel label="Major Action" required />
          <SelectBox
            name="major_action_id"
            options={majorAction}
            placeholder="Select major action"
            value={
              formData.major_action_id
                ? majorAction.find(
                    (element) => element.value === formData.major_action_id
                  )
                : null
            }
            onChange={handleMajorActionChange}
            error={error.major_action_id}
          />
        </div>
        <div className="form-group work-tracker-form__dropdown">
          <FormLabel label="Minor Action" required />
          <SelectBox
            name="minor_action_id"
            options={minorAction}
            placeholder="Select minor action"
            value={
              formData.minor_action_id
                ? minorAction.find(
                    (element) => element.value === formData.minor_action_id
                  )
                : null
            }
            onChange={handleMinorActionChange}
            error={error.minor_action_id}
          />
        </div>
        <div className="form-group work-tracker-form__textarea">
          <FormLabel label="Note" required />

          <textarea
            className="form__control"
            name="user_note"
            value={formData.user_note}
            placeholder="User note ..."
            spellCheck={false}
            onChange={handleOnChange}
            onBlur={handleFormBlur}
          />
          {error.user_note && (
            <p className="user-form__error-msg text-sm mt-1x">
              {error.user_note}
            </p>
          )}
        </div>
        <div className="form-group work-tracker-form__dropdown work-tracker-form__full-row">
          <FormLabel label="Transaction Combo" />
          <SelectBox
            name="transaction_combo"
            isSearchable={true}
            onInputChange={(transactionCodeName) => {
              if (transactionCodeName) {
                fetchTransactionCombo(transactionCodeName);
              }
            }}
            value={
              formData.transaction_combo_id
                ? transactionCombo.find(
                    (element) => element.value === formData.transaction_combo_id
                  )
                : null
            }
            options={transactionCombo}
            onChange={(v) => {
              setFormData((prevState) => ({
                ...prevState,
                transaction_combo_id: v.value,
              }));
            }}
            placeholder="Select transaction combo"
            error={error.transaction_combo_id}
          />
        </div>
        <div className="work-tracker-form__post-details">
          <div className="form-group work-tracker-form__text">
            <FormLabel label="Write off Amount" />

            <Input
              type="number"
              name="writeoff_amount"
              value={formData.writeoff_amount}
              className="form__control"
              onChange={handleOnChange}
              onBlur={handleFormBlur}
              error={error.writeoff_amount}
            />
          </div>

          <div className="form-group work-tracker-form__text">
            <FormLabel label="Posting ID" />

            <Input
              type="text"
              name="posting_id"
              value={formData.posting_id}
              className="form__control"
              onChange={handleOnChange}
              error={error.posting_id}
            />
          </div>
          <div className="form-group work-tracker-form__dropdown">
            <FormLabel label="Post Date" required />
            <Input
              type="date"
              className="form__control"
              name="post_date"
              value={formData.post_date}
              onChange={handleOnChange}
              placeholder="Select Post Date"
              error={error.post_date}
              max={new Date().toISOString().split('T')[0]} 
            />
          </div>
        </div>
        <div>
          <button
            className={classNames('btn btn-primary', {
              'has-loader': isSubmitting,
            })}
            style={{ maxWidth: '100px' }}
            onClick={handleSubmitButtonClick}
            disabled={
              isSubmitting ||
              !Object.values(error).every((value) => value === '')
            }
          >
            Submit
            {isSubmitting && <span className="spinner" />}
          </button>

          <button
            className="btn m-1x color-primary--base"
            onClick={() => {
              setFormData(initialFormData);
              setError({});
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </>
  );
};

WorkTrackerForm.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  formSubmitted: PropTypes.func.isRequired,
};

export default WorkTrackerForm;
