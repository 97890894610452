import http from 'utils/http';
import config from '../config';

export async function getWorkTrackers(params = {}) {
  const url = config.endpoints.workTrackers;
  const { data } = await http.get(url, { params });

  return data.data;
}

export async function addWorkTrackers(body) {
  const url = config.endpoints.workTrackers;
  const { data } = await http.post(url, { ...body });

  return data.data;
}

export async function getWorkTrackerAggByWeek(params = {}) {
  const url = config.endpoints.workTrackersCountAgg;
  const { data } = await http.get(url, { params });
  
  return data.data;
}
